// // BEFORE UNLOAD
// function beforeUnloadListener(event){
//     console.log("BEFORE UNLOAD2")
// 	console.log( event )
// 	var changed = document.getElementById('changed').innerHTML
// 	if (changed === "true") {


// 		swal({
// 			title: "Are you sure?",
// 			text: "Once deleted, you will not be able to recover this imaginary file!",
// 			icon: "warning",
// 			buttons: true,
// 			dangerMode: true,
// 		  })
// 		  .then((willDelete) => {
// 			if (willDelete) {

// 				window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
// 				window.location.href = link.href;

		
// 			}
// 			else
// 			{

// 			}

// 		  });


// 		  event.preventDefault();
		  

// 		// event.preventDefault();
// 		// event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
// 		// return 'You have unsaved changes. Are you sure you want to leave?'
// 	}
// };

const BeforeUnloadHook = {
mounted() {

	// need to load text

	var current_url = window.location.href;
	//reload page listener
	//window.addEventListener("beforeunload", beforeUnloadListener, { capture: true });

	//window.addEventListener("beforeunload", beforeUnloadListener, { capture: true });
	console.log("Before Unload Hook");

	window.addEventListener("beforeunload", function(event) {


		// console.log( event );
		// console.log( event.target.location.href )
			
			var changed = document.getElementById('changed').innerHTML;

			console.log(changed);

			if (changed === "true") {


				console.log("Change is true [beforeunload]");
				
				// swal({
				// 	title: "Are you sure?",
				// 	text: "You have unsaved changes. Are you sure you want to leave?",
				// 	icon: "warning",
				// 	buttons: true,
				// 	dangerMode: true,
				//   })
				//   .then((willDelete) => {
				// 	if (willDelete) {

				// 		//window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
				// 		window.location.href = link.href;

				
				// 	}
				// 	else
				// 	{
				// 		event.preventDefault();
				// 		event.stopImmediatePropagation();
				// 	}

				//   });
				
				// const confirmationMessageLink = 'You have unsaved changes. Are you sure you want to leave?';
				// if (confirm(confirmationMessageLink)) {
				//     window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
				//     window.location.href = link.href;
				// } else {
				//     event.preventDefault();
				//     event.stopImmediatePropagation();
				// }
			
				
				event.preventDefault();
				event.stopImmediatePropagation();
				
			}

		
		}, true);



	// //back page listener
	// window.addEventListener("popstate", function(event){
	// console.log("popstate")
	// var changed = document.getElementById('changed').innerHTML;
	// if (changed === "true") {
	// 	const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
	// 	if (!confirm(confirmationMessage)) {
	// 		console.log(current_url)
	// 		history.pushState(null, '', current_url)
	// 		event.preventDefault();
	// 		event.stopImmediatePropagation();
	// 		return false;
	// 	}
	// }
	// });
    // document.querySelectorAll('a').forEach((link) => { 
    //     console.log(link)
    // });
    
    
	//link listener
    
	document.addEventListener('click', function(event) {
		console.log("Click Unload");
		console.log(event);

        if (event.target.matches('a')) {
            var link = event.target;

			//console.log("Click Unload");
           
            //if(link.classList.contains('class_before_unload') || link.getAttribute('data-phx-link') === 'redirect'){
			if(link.classList.contains('class_before_unload') == true) {
			
                var changed = document.getElementById('changed').innerHTML;

				if (changed === "true") {


					//console.log("Change is true [click]");
					event.preventDefault();
					event.stopImmediatePropagation();

					// swal({
					// 	title: "Are you sure?",
					// 	text: "You have unsaved changes. Are you sure you want to leave?",
					// 	icon: "warning",
					// 	buttons: true,
					// 	dangerMode: true,
					//   })
					//   .then((willDelete) => {
					// 	if (willDelete) {

					// 		//window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
					// 		window.location.href = link.href;

					
					// 	}
					// 	else
					// 	{
					// 		event.preventDefault();
					// 		event.stopImmediatePropagation();
					// 	}

					//   });
					
					const confirmationMessageLink = 'You have unsaved changes. Are you sure you want to leave?';
                    if (confirm(confirmationMessageLink)) {
                        //window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
                        window.location.href = link.href;
                    } else {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }
                }
            }
        }
    }, true);
}
} 
export default BeforeUnloadHook;
