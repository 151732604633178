const options = {
	multiselect: {
		allowHTML: true,
		placeholderValue: 'Select...',
		searchPlaceholderValue: 'Search',
		removeItemButton: true,
	},
	multitag: {
		allowHTML: true,
		placeholderValue: 'Digit new value...',
		searchPlaceholderValue: 'Search',
		removeItemButton: true,
	},
};

const ChoicesHook = {

	mounted() {

		initial_options = options[this.el.dataset.type]
		id = this.el.dataset.id
		choices = new Choices(this.el, initial_options);

		console.log( "Mounted MultiSelect: " + id)
		console.log( initial_options )
		console.log( this.el )


		this.handleEvent('update_choices_'+id, (data) => {
		
			console.log( "Update Choices" )
			console.log( this.el )
			console.log( data)
			console.log( initial_options)
			initial_options.choices = data.choices
			console.log(initial_options);

			choices.destroy();		
			choices = new Choices(this.el, initial_options );

		});

	},
	updated() {
		console.log( "Updated MultiSelect")

		console.log ( this.el )
		console.log ( this.el.dataset.type)
		console.log ( this.el.dataset.selected)
		
		console.log( choices )

		choices.destroy();
		choices = new Choices(this.el, options[this.el.dataset.type]);
	},
};

// let ChoicesHook = {
// 	mounted() {
// 		console.log('choices mounted');
// 		if (!this.el.dataset.choicesInitialized) {
// 			this.initializeChoices();
// 			this.el.dataset.choicesInitialized = true;
// 		}
// 	},
// 	updated() {
// 		console.log('choices updated');
// 		if (!this.el.dataset.choicesInitialized) {
// 			this.initializeChoices();
// 			this.el.dataset.choicesInitialized = true;
// 		}
// 	},
// 	initializeChoices() {
// 		console.log('choices Initialized');

// 		resp = new Choices(this.el, options[this.el.dataset.type]);
// 		console.log('RESP: ', resp);
// 	},
// };

export default ChoicesHook;
