// If you want to use Phoenix channels, run `mix help phx.gen.channel`
// to get started and then uncomment the line below.
// import "./user_socket.js"

// You can include dependencies in two ways.
//
// The simplest option is to put them in assets/vendor and
// import them using relative paths:
//
//     import "../vendor/some-package.js"
//
// Alternatively, you can `npm install some-package --prefix assets` and import
// them using a path starting with the package name:
//
//     import "some-package"
//

// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
import 'phoenix_html';
// Establish Phoenix Socket and LiveView configuration.
import { Socket } from 'phoenix';
import { LiveSocket } from 'phoenix_live_view';
import Alpine from 'alpinejs';
import { LiveMenu } from './defaultmenu';
import 'simplebar';
import 'particles.js';

// Alpine Directives

import usePopper from './usePopper';

// Alpine Directives
import tooltip from './tooltip';

import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import BeforeUnloadHook from './hooks/before_unload_hooks';

import flatpickr from 'flatpickr'; // @see https://flatpickr.js.org/

import Croppie from 'croppie';

import LogHook from './hooks/log_hook';
import ResizerHook from './hooks/resizer_hook';
import PannelResizerHook from './hooks/panel_resize_ehook';
// Croppie
import CroppieHook from './hooks/croppie_hook';
import WidgetChangeHook from './hooks/widget_change';
import VegaWatchHook from './hooks/vega_watch_hook';
import VegaLineChartHook from './hooks/vega_line_chart_hook';
import ParticlesHook from './hooks/particles_hook';
import ColumnsDropdownHook from './hooks/table_dropdown_hook';
import CsvExportHook from './hooks/csv_export_hook';
import ColorSelectHook from './hooks/color_select_hook';
import RegisterTypeSelectHook from './hooks/register_type_select_hook';
import GridstackHook from './hooks/gridstack_hook';
import IndexHeightHook from './hooks/index_height_hook';

import ChoicesHook from './hooks/choices_hook';
import LoginSwiperHook from './hooks/login_swiper_hook';

import ApexCharts from 'apexcharts';

var fr = require('apexcharts/dist/locales/fr.json');
var en = require('apexcharts/dist/locales/en.json');
var de = require('apexcharts/dist/locales/de.json');
var it = require('apexcharts/dist/locales/it.json');

// console.log('fr: ', fr);
// console.log('it: ', it);
// console.log('de: ', de);
// console.log('en: ', en);

import { CodeEditorHook } from '../../../deps/live_monaco_editor/priv/static/live_monaco_editor.esm';

// AlpineJS Plugins
import persist from '@alpinejs/persist'; // @see https://alpinejs.dev/plugins/persist
import collapse from '@alpinejs/collapse'; // @see https://alpinejs.dev/plugins/collapse
import intersect from '@alpinejs/intersect'; // @see https://alpinejs.dev/plugins/intersect

import swal from 'sweetalert';

import { MarkerClusterer } from '@googlemaps/markerclusterer';

//import topbar from "../vendor/topbar"
//TODO: i wasn't able to import. package.json is a module and topbar is not exported well,
// i tried also to renami it *.cjs but does not work

let liveMenu = undefined;

let Hooks = {};

Hooks.CodeEditorHook = CodeEditorHook;
Hooks.Resizer = ResizerHook;
Hooks.PannelResizer = PannelResizerHook;
Hooks.Log = LogHook;
Hooks.Croppie = CroppieHook;
Hooks.WidgetChange = WidgetChangeHook;
Hooks.VegaWatch = VegaWatchHook;
Hooks.VegaLineChart = VegaLineChartHook;
Hooks.Particles = ParticlesHook;
Hooks.ColumnsDropdown = ColumnsDropdownHook;
Hooks.CsvExport = CsvExportHook;
Hooks.ColorSelect = ColorSelectHook;
Hooks.ChoicesHook = ChoicesHook;
Hooks.LoginSwiper = LoginSwiperHook;
Hooks.RegisterTypeSelect = RegisterTypeSelectHook;
Hooks.BeforeUnload = BeforeUnloadHook;
Hooks.Gridstack = GridstackHook;
Hooks.IndexHeight = IndexHeightHook;

Hooks.DisableWithSpinner = {
	mounted() {
		this.el.addEventListener('click', (e) => {
			// Change icon to notice that the action is running
			let icon = this.el.querySelector('i');
			icon.dataset.originalClass = icon.className; // Save original class
			icon.className =
				'bx bx-loader bx-spin ' + this.el.getAttribute('other-class-spinner'); // Change icon and add some other custom class to the spinner to manage different situations

			// Disable other clicks
			this.el.setAttribute('disabled', 'disabled');

			// Revert icon when event is complete
			this.handleEvent('action_complete', () => {
				icon.className = icon.dataset.originalClass; // Revert original class
				this.el.removeAttribute('disabled');
			});
		});
	},
};

Hooks.UIComponentMiniChart = {
	mounted() {
		const chartConfig = JSON.parse(this.el.dataset.config);
		const seriesData = JSON.parse(this.el.dataset.series);
		const categoriesData = JSON.parse(this.el.dataset.categories);

		const options = {
			chart: Object.assign(
				{
					background: 'transparent',
				},
				chartConfig
			),
			series: seriesData,
			xaxis: {
				show: false,
				labels: {
					show: false,
				},
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			yaxis: {
				show: false,
				labels: {
					show: false,
				},
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			// xaxis: {
			// 	// categories: categoriesData,
			// 	type: 'datetime',
			// 	labels: {
			// 		labels: {
			// 			datetimeFormatter: {
			// 				year: 'yyyy',
			// 				month: "MMM 'yy",
			// 				day: 'dd MMM',
			// 				hour: 'HH:mm',
			// 			},
			// 		},
			// 	},
			// },
			// tooltip: {
			// 	x: {
			// 		format: 'dd MMM yyyy HH:mm:ss',
			// 	},
			//},
		};

		const chart = new ApexCharts(this.el, options);
		chart.render();

		chart_id = this.el.getAttribute('id');

		this.handleEvent('uc-' + chart_id, (e) => {
			// window.addEventListener("phx:uc-" + chart_id, (e) => {

			chart.updateSeries([
				{
					data: e.dataset,
				},
			]);
		});
	},
};

Hooks.UIComponentChart = {
	mounted() {
		const chartConfig = JSON.parse(this.el.dataset.config);
		const seriesData = JSON.parse(this.el.dataset.series);
		const categoriesData = JSON.parse(this.el.dataset.categories);
		// const locale = this.el.dataset.locale;

		// console.log(locale);

		const options = {
			chart: Object.assign(
				{
					background: 'transparent',
					// locales: [fr, en, it, de],
					// defaultLocale: en,
				},
				chartConfig
			),
			series: seriesData,
			xaxis: {
				// categories: categoriesData,
				type: 'datetime',
				labels: {
					datetimeUTC: false,
					datetimeFormatter: {
						year: 'yyyy',
						month: "MMM 'yy",
						day: 'dd MMM',
						hour: 'HH:mm',
					},
				},
			},
			tooltip: {
				x: {
					format: 'dd MM yyyy HH:mm:ss',
				},
			},
		};

		if (chartConfig.yaxis) {
			// add yaxis to options
			Object.assign(options, { yaxis: chartConfig.yaxis });
		}

		// same for colors
		if (chartConfig.colors) {
			Object.assign(options, { colors: chartConfig.colors });
		}

		const chart = new ApexCharts(this.el, options);
		chart.render();

		chart_id = this.el.getAttribute('id');

		this.handleEvent('uc-' + chart_id, (e) => {
			// window.addEventListener("phx:uc-" + chart_id, (e) => {

			chart.updateSeries([
				{
					data: e.dataset,
				},
			]);
		});
	},
};

Hooks.UIComponentChart2 = {
	mounted() {
		this.updateDataSeries(this.el);

		this.handleEvent('reload_graph', () => {
			this.updateDataSeries(this.el);
		});
	},

	updateDataSeries(element) {
		const chartConfig = JSON.parse(element.dataset.config);
		const seriesData = JSON.parse(element.dataset.series);
		const categoriesData = JSON.parse(element.dataset.categories);

		const options = {
			chart: Object.assign(
				{
					background: 'transparent',
				},
				chartConfig
			),
			series: seriesData,
			xaxis: {
				type: 'datetime',
				labels: {
					datetimeUTC: false,
					datetimeFormatter: {
						year: 'yyyy',
						month: "MMM 'yy",
						day: 'dd MMM',
						hour: 'HH:mm',
					},
				},
			},
			tooltip: {
				x: {
					format: 'dd MM yyyy HH:mm:ss',
				},
			},
		};

		if (chartConfig.yaxis) {
			Object.assign(options, { yaxis: chartConfig.yaxis });
		}

		if (chartConfig.colors) {
			Object.assign(options, { colors: chartConfig.colors });
		}

		const chart = new ApexCharts(element, options);
		chart.render();

		const chart_id = element.getAttribute('id');

		this.handleEvent('uc-' + chart_id, (e) => {
			chart.updateSeries([
				{
					data: e.dataset,
				},
			]);
		});
	},
};

Hooks.TaskSort = {
	mounted() {
		let sorter = new Sortable(this.el, {
			sort: true,
			handle: '.my-handle',
			animation: 150,
			delay: 100,
			// dragClass: 'drag-item',
			ghostClass: 'drag-ghost',
			filter: '.nosort',
			fallbackOnBody: false,
			forceFallback: false,
			group: 'shared',
			onEnd: (e) => {
				elements = Array.from(document.getElementsByClassName('sortable-card'));

				destination_order = elements.map((x) => x.dataset['id']);

				let params = {
					data: sorter.toArray(),
					old: e.oldIndex,
					new: e.newIndex,
					// sortable: sortable_id,
					// destination_node: destination_node,
					destination_order: destination_order,
					...e.item.dataset,
				};

				this.pushEventTo(this.el, 'reposition', params);
			},
		});
	},
};

Hooks.Height = {
	mounted() {
		window.addEventListener('notifyHeightHook', (e) => {
			//console.log('Second hook activated!', e.detail);
			// Perform actions based on event
			//console.log('Grid IDthiss: ', this.el.id);
			//console.log('Grid ID: ', grid_id);
			grid = document.getElementById(grid_id);
			//console.log('Grid Element:', grid);
			// height = this.el.clientHeight;
			// width = this.el.clientWidth;
			gap = document.getElementById(grid_id + '_inner');
			const style = getComputedStyle(grid);
			//console.log('Gap:', style.getPropertyValue('gap'));
			//remove temporarely all cards to see the real height
			document.getElementById(grid_id + '_inner').style.display = 'none';

			height = grid.clientHeight;
			width = grid.clientWidth;

			//re-show the cards
			document.getElementById(grid_id + '_inner').style = undefined;

			//console.log('Grid Height:' + height);
			//console.log('Grid Width:' + width);

			card = document.getElementById(grid_id + '_card_0');
			pagination_bar = document.getElementById('grid-pagination_1');
			if (card == null) {
				card_height = 1;
				card_width = 1;
			} else {
				card_height = card.clientHeight + 16;
				card_width = card.clientWidth + 16;
			}

			if (pagination_bar == null) {
				pagination_bar_height = 50;
			} else {
				pagination_bar_height = pagination_bar.clientHeight + 17;
			}

			//console.log('Card Height:' + card_height);
			//console.log('Card Width:' + card_width);

			let params = {
				grid_id: grid_id,
				height: height,
				width: width,
				card_height: card_height,
				card_width: card_width,
				pagination_bar_height: pagination_bar_height,
			};

			this.pushEventTo(element_for_messages, 'resize_canvas', params);
		});

		height = this.el.clientHeight;
		width = this.el.clientWidth;
		//console.log('Mounted Height:' + height);
		//console.log('Mounted Width:' + width);

		element_for_messages = this.el;

		grid_id = this.el.id;

		card = document.getElementById(grid_id + '_card_0');
		pagination_bar = document.getElementById('grid-pagination_1');
		if (card == null) {
			//console.log('Card is null');
			card_height = 112;
			card_width = 400;
		} else {
			card_height = card.clientHeight + 16;
			card_width = card.clientWidth + 16;
		}

		if (pagination_bar == null) {
			pagination_bar_height = 50;
		} else {
			pagination_bar_height = pagination_bar.clientHeight + 17;
		}

		let params = {
			grid_id: grid_id,
			height: height,
			width: width,
			card_height: card_height,
			card_width: card_width,
			pagination_bar_height: pagination_bar_height,
		};

		this.pushEventTo(element_for_messages, 'resize_canvas', params);

		// this handles the resize from phoenix
		//this.handleEvent('resize', gridResize(id));

		//COMMENTED by Ettore: this piece of code goes in loop when you resize inside a tab to max width

		// this.handleEvent('resize', (e) => {
		// 	console.log('Received push_evenet');
		// 	console.log(e.grid_id);

		// 	var resizeForGrid = new CustomEvent('resize', {
		// 		detail: e.grid_id,
		// 	});

		// 	window.dispatchEvent(resizeForGrid);
		// });

		// this is the resize event listener from the global window resize event
		window.addEventListener('resize', (event) => {
			grid = document.getElementById(grid_id);

			// height = this.el.clientHeight;
			// width = this.el.clientWidth;

			height = grid.clientHeight;
			width = grid.clientWidth;

			card = document.getElementById(grid_id + '_card_0');
			pagination_bar = document.getElementById('grid-pagination_1');
			if (card == null) {
				card_height = 1;
				card_width = 1;
			} else {
				card_height = card.clientHeight + 16;
				card_width = card.clientWidth + 16;
			}

			if (pagination_bar == null) {
				pagination_bar_height = 50;
			} else {
				pagination_bar_height = pagination_bar.clientHeight + 17;
			}

			let params = {
				grid_id: grid_id,
				height: height,
				width: width,
				card_height: card_height,
				card_width: card_width,
				pagination_bar_height: pagination_bar_height,
			};

			this.pushEventTo(element_for_messages, 'resize_canvas', params);
		});
	},
};

Hooks.LiveMenu = {
	mounted() {
		liveMenu = new LiveMenu();
		if (liveMenu.closed) {
			liveMenu.toggleSidemenu();
			liveMenu.setNavActive(true);
		}
	},
	updated() {
		liveMenu = new LiveMenu();
		if (liveMenu.closed) {
			liveMenu.toggleSidemenu();
			liveMenu.setNavActive(true);
		}
	},
};

Hooks.LiveUsers = {
	mounted() {
		var myElement21 = document.getElementById('teams-nav');
		new SimpleBar(myElement21, { autoHide: true });
	},
};

Hooks.GroupSort = {
	mounted() {
		let sorter = new Sortable(this.el, {
			sort: true,
			handle: '.my-handle',
			animation: 150,
			delay: 100,
			// dragClass: 'drag-item',
			ghostClass: 'drag-ghost',
			filter: '.nosort',
			fallbackOnBody: false,
			forceFallback: false,
			group: 'shared',
			onEnd: (e) => {
				elements = Array.from(e.to.getElementsByTagName('li'));

				destination_order = elements.map((x) => x.dataset['id']);

				sortable_id = e.srcElement.dataset['node_id'];
				destination_node = e.to.dataset['node_id'];

				let params = {
					data: sorter.toArray(),
					old: e.oldIndex,
					new: e.newIndex,
					sortable: sortable_id,
					destination_node: destination_node,
					destination_order: destination_order,
					...e.item.dataset,
				};
				this.pushEventTo(this.el, 'reposition', params);
			},
		});
	},
};

Hooks.Sortable = {
	mounted() {
		let sorter = new Sortable(this.el, {
			handle: '.my-handle',
			animation: 150,
			delay: 100,
			//   dragClass: "drag-item",
			ghostClass: 'drag-ghost',
			filter: '.nosort',
			forceFallback: false,
			onEnd: (e) => {
				sortable_id = e.srcElement.dataset['list_id'];

				let params = {
					data: sorter.toArray(),
					old: e.oldIndex,
					new: e.newIndex,
					sortable: sortable_id,
					...e.item.dataset,
				};
				this.pushEventTo(this.el, 'reposition', params);
			},
		});
	},
};

Hooks.InfiniteScroll = {
	page() {
		return this.el.dataset.page;
	},
	loadMore(entries) {
		const target = entries[0];
		if (target.isIntersecting && this.pending == this.page()) {
			this.pending = this.page() + 1;
			this.pushEvent('load-more', {});
		}
	},
	checkIfVisible() {
		// rect is the bounding rectangle of the element
		const rect = this.el.getBoundingClientRect();
		// isVisible checks if the element is in the viewport
		const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
		// if the element is visible and the pending page is the current page then load moreparseShapeCords
		if (isVisible && this.pending === this.page()) {
			this.pending = this.page() + 1;
			this.pushEvent('load-more', {});
		}
	},
	mounted() {
		this.pending = this.page();
		this.observer = new IntersectionObserver(
			(entries) => this.loadMore(entries),
			{
				root: null, // window by default
				rootMargin: '200px',
				threshold: 0.1,
			}
		);
		this.observer.observe(this.el);
		// Check if the element is already visible
		this.checkIfVisible();
	},
	destroyed() {
		this.observer.unobserve(this.el);
	},
	updated() {
		this.pending = this.page();
		this.checkIfVisible();
	},
};

let phoenixMapEvent;
let map;
let all_overlays = [];
let selectedShape;
let infoWindow;
let croppyInstance;

async function initMap() {
	// Request needed libraries.
	const { Map, InfoWindow } = await google.maps.importLibrary('maps');
	const { AdvancedMarkerElement, PinElement } =
		await google.maps.importLibrary('marker');
	const { DrawingManager } = await google.maps.importLibrary('drawing');

	infoWindow = new google.maps.InfoWindow();
	let drawingManager = new google.maps.drawing.DrawingManager({
		drawingMode: google.maps.drawing.OverlayType.null,
		drawingControl: false,
		drawingControlOptions: {
			position: google.maps.ControlPosition.TOP_CENTER,
			drawingModes: ['polygon'],
		},
		polygonOptions: {
			fillColor: '#ffff00',
			strokeWeight: 1,
			editable: true,
		},
	});

	map = new google.maps.Map(document.getElementById('map'), {
		center: new google.maps.LatLng(12.7, 23.28583612505606),
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoom: 7,
		streetViewControl: false,
		mapTypeControl: false,
		fullscreenControl: false,
	});
}
const zoomOptions = {
	limits: {
		x: { min: 0, max: 120 },
		y: { min: 0, max: 120 },
	},
	zoom: {
		wheel: {
			enabled: false,
		},
		pinch: {
			enabled: false,
		},
		mode: 'xy',
		scaleMode: 'xy',
	},
	pan: {
		enabled: false,
		mode: 'xy',
		scaleMode: 'xy',
	},
};

function getCoordinates(shape) {
	return shape
		.getPath()
		.getArray()
		.map((cord) => {
			return {
				lat: cord.lat(),
				lng: cord.lng(),
			};
		});
}
function clearSelection() {
	if (selectedShape) {
		selectedShape.setEditable(false);
		selectedShape = null;
	}
}
function setSelection(shape) {
	clearSelection();
	selectedShape = shape;
	shape.setEditable(true);
}
function deleteSelectedShape() {
	if (selectedShape) {
		phoenixMapEvent.pushEvent(':shape.delete_selection', {
			coordinates: getCoordinates(selectedShape),
			id: selectedShape.id,
			name: selectedShape.name,
		});
		if (selectedShape.name === selectedShape.current_geofence) {
			selectedShape.setMap(null);
		}
	}
}
function parseShapeCords(shape) {
	return shape.map((cord) => {
		return {
			lat: parseFloat(cord.lat),
			lng: parseFloat(cord.lng),
		};
	});
}

function parseShapeCords2(shape) {
	return shape.map((cord) => {
		return {
			lat: parseFloat(cord[0]),
			lng: parseFloat(cord[1]),
		};
	});
}

function buildBounding(shape) {
	var latlngbounds = new google.maps.LatLngBounds();

	return shape.map((cord) => {
		return new google.maps.LatLng(cord[0], cord[1]);
	});
}

function showInfo(event, newShape) {
	// Since this polygon has only one path, we can call getPath() to return the
	// MVCArray of LatLngs.
	// @ts-ignore
	shape_name = newShape?.name?.toUpperCase() ?? '';
	const polygon = newShape;

	const vertices = polygon.getPath();
	let contentString = `<b style="font-weight: 700;">Geofence Template Name : ${shape_name}</b><br><br> <b style="font-weight: 500;"> Clicked location:</b> <br>
    <b>Lat: ${event.latLng.lat()} </b> <br>
    <b>Long:  ${event.latLng.lng()}</b> <br>`;
	// Iterate over the vertices.
	for (let i = 0; i < vertices.getLength(); i++) {
		const xy = vertices.getAt(i);
		contentString += `<br><b style="font-weight: 500;">Coordinate ${i + 1}:</b>
    <br>
    <b>${xy.lat()}, ${xy.lng()}</b>`;
	}
	// Replace the info window's content and position.
	infoWindow.setContent(contentString);
	infoWindow.setPosition(event.latLng);
	infoWindow.open(map);
}

// Geofence InitMap

async function geofenceInitMap(positions, zoom, shapes, relay) {
	// Request needed libraries.
	const { Map, InfoWindow } = await google.maps.importLibrary('maps');
	const { AdvancedMarkerElement, PinElement } =
		await google.maps.importLibrary('marker');
	const { DrawingManager } = await google.maps.importLibrary('drawing');

	infoWindow = new google.maps.InfoWindow();
	let drawingManager = new google.maps.drawing.DrawingManager({
		drawingMode: google.maps.drawing.OverlayType.null,
		drawingControl: false,
		drawingControlOptions: {
			position: google.maps.ControlPosition.TOP_CENTER,
			drawingModes: ['polygon'],
		},
		polygonOptions: {
			fillColor: '#ffff00',
			strokeWeight: 1,
			editable: true,
		},
	});

	map = new google.maps.Map(document.getElementById('map'), {
		center: new google.maps.LatLng(12.7, 23.28583612505606),
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoom: 7,
		streetViewControl: false,
		mapTypeControl: false,
		fullscreenControl: false,
	});

	let position =
		positions == null
			? new google.maps.LatLng(48.4497958670619, 12.538987420153404)
			: new google.maps.LatLng(positions.latitude, positions.longitude);
	if (positions !== null) {
		new google.maps.Marker({
			map,
			position,
			animation: google.maps.Animation.DROP,
		});
	}
	drawingManager.setMap(map);
	// set shapes
	shapes.forEach((shape) => {
		let newShape = new google.maps.Polygon({
			paths: parseShapeCords(shape),
			strokeColor: '#000',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor:
				shape[0].name === shape[0].current_geofence ? '#ffff00' : '#009fff',
			fillOpacity: 0.35,
			editable: false,
		});
		shape_names = shape[0].name;
		shapes_id = shape[0].id;
		current_geofence = shape[0].current_geofence;
		all_overlays.push(newShape);
		newShape.name = shape_names;
		newShape.current_geofence = current_geofence;
		newShape.id = shapes_id;
		newShape.setMap(map);
		google.maps.event.addListener(newShape, 'click', function () {
			setSelection(newShape);
		});
		newShape.addListener('click', (e) => showInfo(e, newShape));
	});
	// set zoom and center
	map.setZoom(zoom);
	map.setCenter(position);
	// get shapes lat lang when user draw a shape
	google.maps.event.addListener(
		drawingManager,
		'overlaycomplete',
		function (e) {
			all_overlays.push(e);
			if (e.type != google.maps.drawing.OverlayType.MARKER) {
				// Switch back to non-drawing mode after drawing a shape.
				drawingManager.setDrawingMode(null);
				// Add an event listener that selects the newly-drawn shape when the user
				// mouses down on it.
				var newShape = e.overlay;
				newShape.type = e.type;
				google.maps.event.addListener(newShape, 'click', function () {
					setSelection(newShape);
				});
				newShape.addListener('click', (e) => showInfo(e, newShape));
				setSelection(newShape);
			}
			let arrayOfLatLng = [];
			newShape
				.getPath()
				.getArray()
				.forEach((element) => {
					arrayOfLatLng.push({
						latitude: element.lat(),
						longitude: element.lng(),
					});
				});
			relay.pushEvent('new-shape', { arrayOfLatLng });
		}
	);
	// get shapes lat lang when user edit a shape
	google.maps.event.addListener(
		drawingManager,
		'overlaycomplete',
		function (event) {
			if (event.type == google.maps.drawing.OverlayType.POLYGON) {
				google.maps.event.addListener(event.overlay.getPath(), 'set_at', () => {
					let arrayOfPath = event.overlay.getPath().getArray();
					let arrayOfLatLng = [];
					arrayOfPath.forEach((element) => {
						arrayOfLatLng.push({
							latitude: element.lat(),
							longitude: element.lng(),
						});
					});
				});
			}
		}
	);
	google.maps.event.addDomListener(
		document.getElementById('delete-button'),
		'click',
		deleteSelectedShape
	);
	google.maps.event.addListener(
		drawingManager,
		'drawingmode_changed',
		clearSelection
	);
	google.maps.event.addListener(map, 'click', clearSelection);

	document.getElementById('polygonTool').addEventListener('click', function () {
		drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
	});
	document.getElementById('selectTool').addEventListener('click', function () {
		drawingManager.setDrawingMode(null);
	});
}

Hooks.GeofenceDetail = {
	mounted() {
		let relay = this;
		phoenixMapEvent = this;

		const handleNewMarker = ({ positions: positions, zoom, shapes, id }) => {
			geofenceInitMap(positions, zoom, shapes, relay);
		};

		const deleteLastOverlay = () => {
			all_overlays.pop().overlay.setMap(null);
		};
		addNameToShape = ({ name }) => {
			all_overlays[all_overlays.length - 1].overlay.name = name;
		};
		this.handleEvent('marker', handleNewMarker);
		this.handleEvent('deleteLastOverlay', deleteLastOverlay);
		this.handleEvent('addNameToShape', addNameToShape);
	},
};

Hooks.GeofenceDetailGroup = {
	mounted() {
		let relay = this;
		phoenixMapEvent = this;
		infoWindow = new google.maps.InfoWindow();
		let drawingManager = new google.maps.drawing.DrawingManager({
			drawingMode: google.maps.drawing.OverlayType.null,
			drawingControl: false,
			drawingControlOptions: {
				position: google.maps.ControlPosition.TOP_CENTER,
				drawingModes: ['polygon'],
			},
			polygonOptions: {
				fillColor: '#ffff00',
				strokeWeight: 1,
				editable: true,
			},
		});

		const handleNewMarker = ({ positions: positions, zoom, shapes, id }) => {
			initMap();

			let position =
				positions == null
					? new google.maps.LatLng(48.4497958670619, 12.538987420153404)
					: new google.maps.LatLng(positions.latitude, positions.longitude);
			if (positions !== null) {
				new google.maps.Marker({
					map,
					position,
					animation: google.maps.Animation.DROP,
				});
			}
			drawingManager.setMap(map);
			// set shapes
			shapes.forEach((shape) => {
				let newShape = new google.maps.Polygon({
					paths: parseShapeCords(shape),
					strokeColor: '#000',
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor:
						shape[0].name === shape[0].current_geofence ? '#ffff00' : '#009fff',
					fillOpacity: 0.35,
					editable: false,
				});
				shape_names = shape[0].name;
				shapes_id = shape[0].id;
				current_geofence = shape[0].current_geofence;
				all_overlays.push(newShape);
				newShape.name = shape_names;
				newShape.current_geofence = current_geofence;
				newShape.id = shapes_id;
				newShape.setMap(map);
				google.maps.event.addListener(newShape, 'click', function () {
					setSelection(newShape);
				});
				newShape.addListener('click', (e) => showInfo(e, newShape));
			});
			// set zoom and center
			map.setZoom(zoom);
			map.setCenter(position);
			// get shapes lat lang when user draw a shape
			google.maps.event.addListener(
				drawingManager,
				'overlaycomplete',
				function (e) {
					all_overlays.push(e);
					if (e.type != google.maps.drawing.OverlayType.MARKER) {
						// Switch back to non-drawing mode after drawing a shape.
						drawingManager.setDrawingMode(null);
						// Add an event listener that selects the newly-drawn shape when the user
						// mouses down on it.
						var newShape = e.overlay;
						newShape.type = e.type;
						google.maps.event.addListener(newShape, 'click', function () {
							setSelection(newShape);
						});
						newShape.addListener('click', (e) => showInfo(e, newShape));
						setSelection(newShape);
					}
					let arrayOfLatLng = [];
					newShape
						.getPath()
						.getArray()
						.forEach((element) => {
							arrayOfLatLng.push({
								latitude: element.lat(),
								longitude: element.lng(),
							});
						});
					relay.pushEvent('new-shape', { arrayOfLatLng });
				}
			);
			// get shapes lat lang when user edit a shape
			google.maps.event.addListener(
				drawingManager,
				'overlaycomplete',
				function (event) {
					if (event.type == google.maps.drawing.OverlayType.POLYGON) {
						google.maps.event.addListener(
							event.overlay.getPath(),
							'set_at',
							() => {
								let arrayOfPath = event.overlay.getPath().getArray();
								let arrayOfLatLng = [];
								arrayOfPath.forEach((element) => {
									arrayOfLatLng.push({
										latitude: element.lat(),
										longitude: element.lng(),
									});
								});
							}
						);
					}
				}
			);
			google.maps.event.addDomListener(
				document.getElementById('delete-button'),
				'click',
				deleteSelectedShape
			);
			google.maps.event.addListener(
				drawingManager,
				'drawingmode_changed',
				clearSelection
			);
			google.maps.event.addListener(map, 'click', clearSelection);
		};
		document
			.getElementById('polygonTool')
			.addEventListener('click', function () {
				drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
			});
		document
			.getElementById('selectTool')
			.addEventListener('click', function () {
				drawingManager.setDrawingMode(null);
			});

		this.handleEvent('marker', handleNewMarker);
	},
};

let maps = {};
async function initMultipleMap(mapId, lat, lng) {
	// Request needed libraries.
	const { Map, InfoWindow } = await google.maps.importLibrary('maps');
	const { AdvancedMarkerElement, PinElement } =
		await google.maps.importLibrary('marker');
	const { DrawingManager } = await google.maps.importLibrary('drawing');

	maps[mapId] = new google.maps.Map(document.getElementById(`${mapId}`), {
		center: new google.maps.LatLng(lat, lng),
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoom: 7,
		zoomControl: false,
		keyboardShortcuts: false,
		streetViewControl: false,
		mapTypeControl: false,
		fullscreenControl: false,
		draggable: false,
	});
}
async function drawShapes(mapId, shapes) {
	// Request needed libraries.
	const { Map, InfoWindow } = await google.maps.importLibrary('maps');
	const { AdvancedMarkerElement, PinElement } =
		await google.maps.importLibrary('marker');
	const { DrawingManager } = await google.maps.importLibrary('drawing');

	var latlngbounds = new google.maps.LatLngBounds();

	let map = maps[mapId];
	shapes.forEach((shape) => {
		var latlngbounds = new google.maps.LatLngBounds();

		for (var i = 0; i < shape.length; i++) {
			latlng = new google.maps.LatLng(shape[i][0], shape[i][1]);
			latlngbounds.extend(latlng);
		}

		path = parseShapeCords2(shape);

		let newShape = new google.maps.Polygon({
			paths: path,
			strokeColor: '#000',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor:
				shape[0].name === shape[0].current_geofence ? '#ffff00' : '#009fff',
			fillOpacity: 0.35,
			editable: false,
		});
		shape_names = shape[0].name;
		shapes_id = shape[0].id;
		current_geofence = shape[0].current_geofence;
		all_overlays.push(newShape);
		newShape.name = shape_names;
		newShape.current_geofence = current_geofence;
		newShape.id = shapes_id;
		newShape.setMap(map);
		google.maps.event.addListener(newShape, 'click', function () {
			setSelection(newShape);
		});
		newShape.addListener('click', (e) => showInfo(e, newShape));

		map.fitBounds(latlngbounds);
	});
}
Hooks.GeofenceCard = {
	mounted() {
		const mapId = this.el.id;

		const handleMapPosition = ({
			mapId: eventMapId,
			lat,
			lng,
			zoom,
			shapes,
		}) => {
			// console.log('eventMapId - mapId', eventMapId, mapId);
			if (mapId !== eventMapId) return;

			if (lat == null || lng == null) {
				lat = 48.4497958670619;
				lng = 12.538987420153404;
				zoom = 4;
			}

			initMultipleMap(mapId, lat, lng);
			drawShapes(mapId, shapes);
		};
		this.handleEvent('mapPosition', handleMapPosition);
	},
};

/* This is Location Map */

markers = [];

function handleLocationError(browserHasGeolocation, infoWindow, pos) {
	infoWindow.setPosition(pos);
	infoWindow.setContent(
		browserHasGeolocation
			? 'Error: The Geolocation service failed.'
			: "Error: Your browser doesn't support geolocation."
	);
	infoWindow.open(map);
}

function addMarker(position) {
	if (markers.length == 0) {
		const marker = new google.maps.Marker({
			position,
			map,
		});

		markers.push(marker);
	} else {
		markers[0].setMap(null);
		markers = [];
		const marker = new google.maps.Marker({
			position,
			map,
		});
		markers.push(marker);
	}
}

async function initSelectDefaultMapPosition(position, relay) {
	// Request needed libraries.
	const { Map, InfoWindow } = await google.maps.importLibrary('maps');
	const { AdvancedMarkerElement, PinElement } =
		await google.maps.importLibrary('marker');

	map = new google.maps.Map(document.getElementById('map'), {
		center: { lat: 49.729, lng: 10.219 },
		zoom: 4,
	});
	infoWindow = new google.maps.InfoWindow();

	if (position !== null) {
		const { lat, lng } = position.position;
		const pos = {
			lat: parseFloat(lat),
			lng: parseFloat(lng),
		};

		addMarker(pos);
		map.setCenter(pos);
		map.setZoom(18);
	}

	const locationButton = document.createElement('button');

	locationButton.textContent = 'Fetch Browser Location';
	locationButton.classList.add('custom-map-control-button');
	map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
	locationButton.addEventListener('click', () => {
		// Try HTML5 geolocation.
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};

					infoWindow.setPosition(pos);
					infoWindow.setContent('Location fetched by browser.');
					infoWindow.open(map);
					map.setCenter(pos);
					map.setZoom(18);
				},
				() => {
					handleLocationError(true, infoWindow, map.getCenter());
				}
			);
		} else {
			// Browser doesn't support Geolocation
			handleLocationError(false, infoWindow, map.getCenter());
		}
	});

	google.maps.event.addListener(map, 'click', function (event) {
		addMarker(event.latLng);
		relay.pushEvent('default-position', {
			lat: event.latLng.lat(),
			lng: event.latLng.lng(),
		});
	});
}

Hooks.MapSelectDefaultPosition = {
	mounted() {
		let relay = this;

		const showDefaultPosition = (position) => {
			initSelectDefaultMapPosition(position, relay);
		};

		this.handleEvent('default_position', showDefaultPosition);
	},
};

async function initSingleLocationMap(position) {
	// Request needed libraries.
	const { Map, InfoWindow } = await google.maps.importLibrary('maps');
	const { AdvancedMarkerElement, PinElement } =
		await google.maps.importLibrary('marker');

	map_id = position.map_id;

	map = new google.maps.Map(document.getElementById(map_id), {
		center: { lat: 49.729, lng: 10.219 },
		zoom: 4,
		streetViewControl: false,
		mapTypeControl: false,
	});
	infoWindow = new google.maps.InfoWindow();

	if (position !== null) {
		const lat = position.lat;
		const lng = position.lng;

		const pos = {
			lat: parseFloat(lat),
			lng: parseFloat(lng),
		};

		const marker = new google.maps.Marker({
			position,
			map,
		});

		map.setCenter(pos);
		map.setZoom(18);
	}
}

Hooks.MapSingleLocationPosition = {
	mounted() {
		let relay = this;

		const showDefaultPosition = (position) => {
			initSingleLocationMap(position);
		};

		this.handleEvent('set_position', showDefaultPosition);
	},
};

Hooks.deletePreFilter = {
	mounted() {
		const action_event = this.el.getAttribute('action_event');
		//   console.log("Hook mounted and listening for event:", action_event);

		this.el.addEventListener(action_event, (event) => {
			event.preventDefault();

			swal({
				title: '',
				text: '',
				buttons: false,
				content: {
					element: 'div',
					attributes: {
						innerHTML: `
				<div class="flex flex-col items-center">
				  <div class="">
					<svg height="156px" width="156px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-5 -5 60.00 60.00" enable-background="new 0 0 50 50" xml:space="preserve" fill="" stroke="">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.8"></g>
					<g id="SVGRepo_iconCarrier">
						<path fill="red" d="M10.289,14.211h3.102l1.444,25.439c0.029,0.529,0.468,0.943,0.998,0.943h18.933 c0.53,0,0.969-0.415,0.998-0.944l1.421-25.438h3.104c0.553,0,1-0.448,1-1s-0.447-1-1-1h-3.741c-0.055,0-0.103,0.023-0.156,0.031 c-0.052-0.008-0.1-0.031-0.153-0.031h-5.246V9.594c0-0.552-0.447-1-1-1h-9.409c-0.553,0-1,0.448-1,1v2.617h-5.248 c-0.046,0-0.087,0.021-0.132,0.027c-0.046-0.007-0.087-0.027-0.135-0.027h-3.779c-0.553,0-1,0.448-1,1S9.736,14.211,10.289,14.211z M21.584,10.594h7.409v1.617h-7.409V10.594z M35.182,14.211L33.82,38.594H16.778l-1.384-24.383H35.182z"></path>
						<path fill="red" d="M20.337,36.719c0.02,0,0.038,0,0.058-0.001c0.552-0.031,0.973-0.504,0.941-1.055l-1.052-18.535 c-0.031-0.552-0.517-0.967-1.055-0.942c-0.552,0.031-0.973,0.504-0.941,1.055l1.052,18.535 C19.37,36.308,19.811,36.719,20.337,36.719z"></path>
						<path fill="red" d="M30.147,36.718c0.02,0.001,0.038,0.001,0.058,0.001c0.526,0,0.967-0.411,0.997-0.943l1.052-18.535 c0.031-0.551-0.39-1.024-0.941-1.055c-0.543-0.023-1.023,0.39-1.055,0.942l-1.052,18.535C29.175,36.214,29.596,36.687,30.147,36.718z"></path>
						<path fill="red" d="M25.289,36.719c0.553,0,1-0.448,1-1V17.184c0-0.552-0.447-1-1-1s-1,0.448-1,1v18.535 C24.289,36.271,24.736,36.719,25.289,36.719z"></path>
					</g>
					</svg>
				  </div>
				  <div class="text-center mt-4">
					<h2 class="text-2xl font-bold mb-2">Are you sure?</h2>
					<p class="text-gray-600 text-lg">The asset will be deleted!</p>
				  </div>

				  <div class="flex justify-end items-center gap-x-4 w-full mt-6">
					<button
					  type="button"
					  id="cancel-btn"
					  class="ti-btn text-white bg-gray-500 hover:bg-gray-300 hover:text-gray-700 label-ti-btn !rounded-full cursor-pointer w-32"
					>
					  <i class="bx bx-reset label-ti-btn-icon me-2 !rounded-full"></i>
					  Cancel
					</button>
					<button
					  type="button"
					  id="confirm-btn"
					  class="ti-btn text-white bg-blue-600 hover:bg-blue-100 hover:text-blue-600 label-ti-btn !rounded-full w-32"
					>
					  <i class="bx bx-save label-ti-btn-icon me-2 !rounded-full"></i>
					  Confirm
					</button>
				  </div>
				</div>
			  `,
					},
				},
				dangerMode: true,
				className: 'custom-swal-border',
			});

			// Event listeners
			document
				.getElementById('cancel-btn')
				.addEventListener('click', function () {
					swal.close();
				});

			document.getElementById('confirm-btn').addEventListener('click', () => {
				let asset_id = this.el.getAttribute('asset_id');
				let action_event = this.el.getAttribute('action_event');

				swal.close();

				this.pushEventTo(this.el, action_event, { asset_id: asset_id });
			});
		});
	},
};

/* This is Location Map */

async function initClusterMap(params) {
	center = params.center;
	locations = params.locations;
	zoom = params.zoom;
	view_params = params.view_params;

	map_id = params.map_id;

	console.log( map_id );
	
	const { Map, InfoWindow } = await google.maps.importLibrary('maps');
	const { AdvancedMarkerElement, PinElement } =
		await google.maps.importLibrary('marker');

	const map = new google.maps.Map(document.getElementById(map_id), {
		zoom: zoom,
		center: { lat: center.lat, lng: center.lng },
		mapId: 'map',
	});
	const infoWindow = new google.maps.InfoWindow({
		content: '',
		disableAutoPan: true,
	});
	// Create an array of alphabetical characters used to label the markers.
	const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	// Add some markers to the map.
	const markers = locations.map((position, i) => {
		const label = labels[i % labels.length];

		// if (position.value > view_params.levels.high) {
		// 	color = 'red';
		// } else if (position.value > view_params.levels.medium) {
		// 	color = 'orange';
		// } else color = 'green';

		color = 'red';

		const pinGlyph = new google.maps.marker.PinElement({
			background: color,
			borderColor: color,
			//glyph: label,
			//glyphColor: 'white',
		});
		const marker = new google.maps.marker.AdvancedMarkerElement({
			position,
			content: pinGlyph.element,
		});

		// markers can only be keyboard focusable when they have click listeners
		// open info window when marker is clicked
		marker.addListener('click', () => {
			if (position.name != null && position.serial != null) {
				contentString = `
				<b>Serial:</b> ${position.serial} <br>
				<b>Name:</b> ${position.name} <br>
 				<b>Location Service: ${position.fix_type}</b> <br>
  				<b>Timestamp: ${position.timestamp}</b> <br>`;
			} else {
				contentString = `
	 				<b>Location Service: ${position.fix_type}</b> <br>
  					<b>Timestamp: ${position.timestamp}</b> <br>`;
			}

			infoWindow.setContent(contentString);
			infoWindow.open(map, marker);

			//infoWindow.setContent(position.lat + ", " + position.lng);
			//infoWindow.setContent('Level: ' + position.serial + '%');
		});
		return marker;
	});

	// Add a marker clusterer to manage the markers.
	new MarkerClusterer({ markers, map });
}

Hooks.ResizerHeight = {
	setHeightToBottom() {
		const contentLeft = document.getElementById('content_left');
		const contentRight = document.getElementById('content_right');
		if (contentLeft) {
			const contentLeftTop = contentLeft.getBoundingClientRect().top; // Distance from top of viewport
			const saveSection = document.getElementById('save_section');
			const offset = saveSection ? 100 : 30;

			contentLeft.style.height = `calc(100vh - ${contentLeftTop + offset}px)`;
			if (contentRight) {
				contentRight.style.height = `calc(100vh - ${
					contentLeftTop + offset
				}px)`;
			}
		}
	},
	mounted() {
		this.setHeightToBottom(); // Set height on mount

		window.addEventListener('resize', this.setHeightToBottom); // Update height on window resize
	},
	updated() {
		this.setHeightToBottom(); // Set height on mount

		window.addEventListener('resize', this.setHeightToBottom); // Update height on window resize
	},
	destroyed() {
		window.removeEventListener('resize', this.setHeightToBottom);
	},
};

Hooks.mapCluster = {
	mounted() {
		const handleLoadMapCluster = (params) => {
			initClusterMap(params);
		};
		this.handleEvent('loadMapCluster', handleLoadMapCluster);
	},
};


window.Alpine = Alpine;
window.flatpickr = flatpickr;
Alpine.plugin(persist);
Alpine.plugin(collapse);
Alpine.plugin(intersect);

Alpine.data('usePopper', usePopper);

Alpine.directive('tooltip', tooltip);

Alpine.start();

let csrfToken = document
	.querySelector("meta[name='csrf-token']")
	.getAttribute('content');
let liveSocket = new LiveSocket('/live', Socket, {
	dom: {
		onBeforeElUpdated(from, to) {
			if (from._x_dataStack) {
				window.Alpine.clone(from, to);
			}
		},
	},
	hooks: Hooks,
	longPollFallbackMs: 2500,
	params: { _csrf_token: csrfToken },
});

// Show progress bar on live navigation and form submits
//topbar.config({barColors: {0: "#29d"}, shadowColor: "rgba(0, 0, 0, .3)"})
//TODO: see comment above of topbar
//window.addEventListener("phx:page-loading-start", _info => topbar.show(300))
//window.addEventListener("phx:page-loading-stop", _info => topbar.hide())

// connect if there are any LiveViews on the page
liveSocket.connect();

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)  // enabled for duration of browser session
// >> liveSocket.disableLatencySim()
window.liveSocket = liveSocket;

window.addEventListener('phx:highlight', (e) => {
	let el = document.getElementById(e.detail.id);
	if (el) {
		el.classList.add('bg-transition-yellow');

		window.setTimeout(function () {
			el.classList.remove('bg-transition-yellow');
			el.classList.add('bg-remove-transition-yellow');
		}, 2000);
	}
});

const unsecuredCopyToClipboard = (text) => {
	const textArea = document.createElement('textarea');
	textArea.value = text;
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();
	try {
		document.execCommand('copy');
	} catch (err) {
		console.error('Unable to copy to clipboard', err);
	}
	document.body.removeChild(textArea);
};

/**
 * Copies the text passed as param to the system clipboard
 * Check if using HTTPS and navigator.clipboard is available
 * Then uses standard clipboard API, otherwise uses fallback
 */
const copyToClipboard = (content) => {
	if (window.isSecureContext && navigator.clipboard) {
		navigator.clipboard.writeText(content);
	} else {
		unsecuredCopyToClipboard(content);
	}
};

window.addEventListener('phx:copy', (event) => {
	let text = event.target.value;
	//alert(text)
	copyToClipboard(text);
});
