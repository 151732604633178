let value = undefined;
const WidgetChangeHook = {
	mounted() {
		console.log('Widget change Hook Mounted....');

		// Dispatch it.
		//this.el.addEventListener('change', changeForm)
		//this.el.dispatchEvent(new Event('change',  { 'bubbles': true }));
		value = this.el.value;
		const lang = this.el.getAttribute('data-language');
		this.pushEventTo(this.el, 'change_language', { language: lang });
	},
	updated() {
		//this.el.addEventListener('change', changeForm)
		const lang = this.el.getAttribute('data-language');
		this.pushEventTo(this.el, 'change_language', { language: lang });
		if (value != this.el.value) {
			this.el.dispatchEvent(new Event('change', { bubbles: true }));
			value = this.el.value;
		}
	},
};

// Hooks.LanguageWidget = {
// 	mounted() {
// 		const lang = this.el.getAttribute('data-language');
// 		console.log('Lang : ', lang);
// 		this.pushEventTo(this.el, 'change_language', { language: lang });
// 	},
// 	updated() {
// 		const lang = this.el.getAttribute('data-language');
// 		console.log('Lang : ', lang);
// 		this.pushEventTo(this.el, 'change_language', { language: lang });
// 	},
// };

export default WidgetChangeHook;
